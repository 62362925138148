import qs from 'query-string';

const jars = [];

const env = new Proxy({}, {
  get(obj, key) {
    const query = qs.parse(window.location.search);
    if (query[key]) return query[key];
    if (localStorage.getItem(key)) return localStorage.getItem(key);
    return jars.reduce((ret, jar) => jar[key] !== undefined ? jar[key] : ret, undefined);
  },
});

export async function addJar(jar) {
  try {
    if (jar) jars.push(jar);
  } finally {
    return env;
  }
}

export default env;