import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
// import * as Sentry from '@sentry/react';
// import { BrowserTracing } from '@sentry/tracing';

import { addJar } from './env';
import pick from 'lodash/pick';

import * as serviceWorker from './serviceWorker';

// Sentry.init({
//   dsn: 'https://ba56e67c9d444d55b57caa52989d967b@o1209220.ingest.sentry.io/6342665',
//   integrations: [new BrowserTracing()],

//   tracesSampleRate: 1.0,
// });

const App = lazy(() => import('./App'));

(async () => {

  const loadJson = async (url, defValue) => {
    try {
      const res = await fetch(url);
      const json = res.json();
      return json || defValue;
    } catch(err) {
      return defValue;
    }
  }

  await addJar({
    REACT_APP_API_URI: 'https://prod-api.smileweb.net',
    REACT_APP_FORM_URL: 'https://form.smileweb.net',
    REACT_APP_BASENAME: '/',
  });

  await addJar(pick(process.env, [
    'REACT_APP_API_URI',
    'REACT_APP_BASENAME',
  ]));
  const manifest = await loadJson('/site.webmanifest');
  await addJar(manifest.env);
  ReactDOM.render(<Suspense fallback={null}><App /></Suspense>, document.getElementById('root'));
})();


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
